import {
    Box, Button, Grid,
    Dialog,
    DialogContent,
    useMediaQuery,
    useTheme,
    DialogTitle,
    IconButton,
    TooltipProps,
    styled,
    Tooltip,
    tooltipClasses,
} from "@mui/material";
import { isEmpty, reject } from "lodash";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../services/models/AlertType";
import { SimpelPdfViwer } from "./SimpelPdfViwer";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.black
        backgroundColor: "#ac8445",
    },
}));
interface MultiUploadProps {
    onDropandSelect: any;
    editFile: any;
    onFileRemove: any;
    onFileDownload: any;
    MAX_COUNT: number;
    removeIconRemove: boolean;
}

const allowedFileTypes = [
    // Excel >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    "application/vnd.ms-excel",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/msexcel",
    "application/x-msexcel",
    "application/x-ms-excel",
    "application/x-excel",
    "application/x-dos_ms_excel",
    "application/xls",
    "application/x-xls",
    "application/x-msi",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx

    // CSV >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    ".csv",
    "text/csv",

    // PDF >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    'application/pdf',

    // Images >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    'image/jpeg',  // .jpg, .jpeg
    'image/png',   // .png
    'image/gif',   // .gif
    'image/bmp',   // .bmp
    'image/tiff',  // .tiff, .tif

    // Plain Text >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    'text/plain',  // .txt

    // Word >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    'application/msword',  // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // .docx

    // PowerPoint >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    'application/vnd.ms-powerpoint',  // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',  // .pptx
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',  // .ppsx
    'application/vnd.openxmlformats-officedocument.presentationml.template',  // .potx
    'application/vnd.ms-powerpoint.template.macroEnabled.12',  // .potm
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',  // .ppsm
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',  // .pptm

    // ZIP >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    'application/zip',          // .zip
    'application/x-zip-compressed', // .zip
    'application/x-zip',        // .zip
    'multipart/x-zip',          // .zip
];


export const MultiUpload: React.FC<MultiUploadProps> = ({ onDropandSelect, editFile, onFileRemove, onFileDownload, MAX_COUNT, removeIconRemove }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [file, setFile] = useState<any>();
    const [fileLimit, setFileLimit] = useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [previewDocDialog, setPreviewDocDialog] = React.useState(false);
    const [previewDoc, setPreviewDoc] = React.useState<any>([]);

    // set file if alreday exists 
    useEffect(() => {
        setFile(
            editFile.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        );
        // setFile(editFile)
    }, [editFile]);

    // on select or on drop file or image 
    const onDrop = useCallback(
        (acceptedFiles) => {
            let limitExceeded = false;
            const uploaded = [...file,];
            const checkForFile: any = [...file, ...acceptedFiles];
            if (file?.length === MAX_COUNT || checkForFile === MAX_COUNT || checkForFile > MAX_COUNT || acceptedFiles > MAX_COUNT) {
                return updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: `You can only add a maximum of ${MAX_COUNT} file`,
                    alertType: AlertType.Error,
                });
            }
            acceptedFiles.some((file) => {
                if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                    uploaded.push(file);
                    if (uploaded.length === MAX_COUNT) setFileLimit(true);
                    if (uploaded.length > MAX_COUNT) {
                        updateUserContext({
                            ...userContext,
                            isAlert: true,
                            alertMessage: `You can only add a maximum of ${MAX_COUNT} file`,
                            alertType: AlertType.Error,
                        });
                        setFileLimit(false);
                        limitExceeded = true;
                        return true;
                    }
                }
            })

            if (!limitExceeded) {
                setFile(
                    uploaded.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file)
                        })
                    )
                );
                // setFile(uploaded);
                onDropandSelect(uploaded);
            }
        },
        [file]
    );
    const customvalidator: any = (file) => {
        const MaxSize = 25;
        if (file.size / 1024 / 1204 > MaxSize) {
            return {
                code: "size-too-large",
                message: `Max allowed file size is 25 MB.`,
            };
        }
        if (!allowedFileTypes.includes(file.type)) {
            return {
                code: "invalid-type",
                message: `Invalid file type. Please upload one of the following types: (.xlsx, .xls, .csv, .pdf, .jpeg, .jpg, .png, .gif, .bmp, .tiff, .txt, .doc, .docx, .ppt, .pptx, .ppsx, .potx, .potm, .ppsm, .pptm, .zip).`,
            };
        }

        return null;
    };
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        validator: customvalidator,
        multiple: true,
        maxFiles: 1
    });

    // errors on file uploadings 
    const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
        <Box>
            {errors.map(e => (
                <span key={e.code} style={{ color: 'red' }}>* {e.message}</span>
            ))}
        </Box>
    ));

    const getFormattedFileSize = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        // JV
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const img = {
        width: '-webkit-fill-available',
    };

    const previewDocDialogBox = (previewFile) => {
        setPreviewDocDialog(true);
        setPreviewDoc([previewFile]);
    };

    const handleCloseFilePreviewDialogBox = (val: any) => {
        setPreviewDocDialog(false);
    };

    return (
        <>
            {isEmpty(file) && (
                <>
                    <Button {...getRootProps({ className: 'dropzone' })} sx={{
                        border: 'dashed gray 1px !important', borderRadius: "6px", padding: '0px !important',
                        '&:hover': {
                            cursor: 'grab'
                        }
                    }}
                        size="medium"
                    >
                        <input {...getInputProps()} />
                        <Box sx={{ justifyContent: 'center' }} className="multi-upload-button">
                            <Box className='left-icon'>
                                <DriveFolderUploadIcon />
                            </Box>
                            <Box style={{ paddingTop: '2px', textAlign: 'center', }} >Upload File</Box>
                        </Box>
                    </Button >
                    <br />
                    <span style={{ fontSize: 'x-small' }}>You can upload a maximum of 1 file, 25MB each </span>
                </>
            )}
            {
                !isEmpty(file) && (
                    <Box sx={{ marginTop: '6px', }}>
                        {file?.length > 0 && file?.map((files: any, index) => {
                            return (
                                <>
                                    <Grid sx={{
                                        alignItems: 'start',
                                        display: 'flex',
                                        backgroundColor: 'transparent',
                                        cursor: "pointer !important"
                                    }}
                                    >
                                        {/* <div  >{previewsDocs}</div> */}
                                        <Button sx={{
                                            justifyContent: 'start',
                                            // maxWidth: "500px",
                                            // minWidth: "240px !important",
                                            textOverflow: "ellipsis ",
                                            //whiteSpace: "nowrap",
                                            //overflow: "hidden",
                                            textAlign: "left",
                                            display: "block"
                                        }}
                                            className="multi-upload-button"
                                            onClick={() => {
                                                onFileDownload(files)
                                            }}>
                                            {files?.name} {" "} ({getFormattedFileSize(files?.size)})
                                        </Button>
                                        {/* <DownloadForOfflineRoundedIcon
                                            onClick={() => {
                                                onFileDownload(files)
                                            }}
                                        /> */}
                                        <span className='multi-upload-right-icon'
                                            onClick={() => {
                                                onFileDownload(files)
                                            }}>
                                            <BootstrapTooltip title={"Download file"} placement="top">
                                                <DownloadForOfflineRoundedIcon sx={{ color: '#ac8445 !important' }} />
                                            </BootstrapTooltip>
                                        </span>

                                        {['image/jpeg', 'image/png'].includes(files?.type) ?
                                            <span className='multi-upload-right-icon' onClick={() => previewDocDialogBox(files)}>
                                                <RemoveRedEyeIcon sx={{ color: '#ac8445 !important' }} /></span> :
                                            <span className='multi-upload-right-icon'>
                                                <BootstrapTooltip title={"You can only view Image"} placement="top">
                                                    <VisibilityOffIcon sx={{ color: 'red !important' }} />
                                                </BootstrapTooltip>
                                            </span>
                                        }
                                        {/* {console.log("data",removeIconRemove)} */}
                                        {!removeIconRemove && (
                                            <span className='multi-upload-right-icon' onClick={() => onFileRemove(files)}>
                                                <BootstrapTooltip title={"Remove file"} placement="top">
                                                    <CloseIcon sx={{ color: 'red' }} />
                                                </BootstrapTooltip>
                                            </span>
                                        )}
                                    </Grid >
                                </>
                            )
                        })}
                    </Box>
                )
            }
            <Box>{fileRejectionItems}</Box>
            {previewDoc?.length > 0 &&
                <Dialog
                    open={previewDocDialog}
                    onClose={handleCloseFilePreviewDialogBox}
                    scroll={"paper"}
                    sx={{ '& .MuiPaper-root': { maxWidth: "revert", width: 1000 } }}
                    maxWidth='md'
                    fullScreen={fullScreen}
                >
                    <DialogTitle className="pdf-viewr-simple">
                        <Grid sx={{ display: 'flew', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h4>
                                <b> {previewDoc[0]?.name}</b>
                            </h4>
                            <BootstrapTooltip title={"Close"} placement="top">
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseFilePreviewDialogBox}
                                    style={{ marginTop: "-10px !important" }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </BootstrapTooltip>
                        </Grid>
                    </DialogTitle>
                    <DialogContent sx={{ marginTop: '5px', padding: (previewDoc?.length > 0 && previewDoc[0]?.type === 'application/pdf') ? '0px !important' : '' }} dividers>
                        <Box>
                            {/* {previewDoc?.length > 0 && ['application/vnd.ms-excel', 'application/pdf'].includes(previewDoc[0]?.type) &&
                                <SimpelPdfViwer
                                    title={previewDoc[0]?.name}
                                    source={previewDoc[0]}
                                />
                            } */}
                            {previewDoc?.length > 0 && ['image/jpeg', 'image/png'].includes(previewDoc[0]?.type) &&
                                <img src={previewDoc[0]?.preview} style={img} alt="Img" />}
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    );
}
